
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from '../../../helpers/fakebackend/auth-header';

import {
  required 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Category",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      title: "Add Category",
      items: [
        {
          text: "Categories",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


  
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      restaurants:[],
    
      posts:{
        stepNO:null,
        categoryName:"",
        restaurantID:"",
     
      }
    };
  },

    validations: {
    posts: {
      categoryName: {
        required,
      },
      // stepNO: {
      //   required,
      // },
      // restaurantID:{
      //   required,
      // }
    },
  },
  methods:{

    submitCategoryForm(e){
   // alert(this.posts.delTime);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
          let formData = new FormData();  
  
            formData.append('categoryName', this.posts.categoryName);
            formData.append('stepNO', this.posts.stepNO);
            //formData.append('restID', this.posts.restaurantID.restID);
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('loginTypeID',this.$storageData.login_type);
    
      e.preventDefault();
      this.axios.post(this.$loggedRole+"/addCategory",  formData, {
          headers: authHeader()
        })
      .then((result)=>{
        this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                  this.$router.push({name: 'categoryTable'}); 
              },2000);
        
         // console.warn(result);

      })
     .catch(function (error) {
       this.loading = false;
                        console.error(error.response);
                        alert("Something went Wrong");
                    });
      }  
    },
        getRestaurantList(){
         this.axios.post(this.$loggedRole+"/getRestaurantList",
          {'restID':this.$storageData.profile.restID,'restBranchID':this.$storageData.profile.restBranchID,'empTypeID':this.$storageData.profile.empTypeID})
          .then((result)=>{
            this.restaurants = result.data.data;
              
        });
    },
  

  },
mounted(){
  //alert();
    this.getRestaurantList();
  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
      <div class="alertMsg">
     <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Category Added Successfully!
    </b-alert>
  </div>
<form @submit.prevent="submitCategoryForm" method="post">

        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                
                <!-- <div class="col-md-12 mb-3">
                      <label>Choose Restaurant Brand* </label>
                     <multiselect v-model="posts.restaurantID" :options="restaurants" track-by="restID" label="name" 
                     :class="{
                        'is-invalid': submitted && $v.posts.restaurantID.$error,
                      }" ></multiselect>
                       <div
                      v-if="submitted && $v.posts.restaurantID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.posts.restaurantID.required"
                        >This value is required.</span
                      >
                    </div>

                </div> -->

                     <div class="mb-3 col-md-12">
                        <label class="form-label" for="formrow-categoryName-input">Category Name *</label>
                        <input type="text" class="form-control" id="formrow-categoryName-input" name="categoryName" 
                        v-model="posts.categoryName"
                        :class="{
                            'is-invalid': submitted && $v.posts.categoryName.$error,
                          }" >
                        <div
                          v-if="submitted && $v.posts.categoryName.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.categoryName.required"
                            >This value is required.</span
                          >
                        </div>
                    </div>
                     <!-- <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-stepNO-input">Step Number*</label>
                        <input type="number" class="form-control" id="formrow-stepNO-input" name="stepNO" 
                        v-model="posts.stepNO"
                        :class="{
                            'is-invalid': submitted && $v.posts.stepNO.$error,
                          }" >
                        <div
                          v-if="submitted && $v.posts.stepNO.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.stepNO.required"
                            >This value is required.</span
                          >
                        </div>
                    </div> -->
              <div class =" row" style="float:right;">
                    <b-spinner v-if="loading"
                    class="m-2 col-3"
                    variant="primary"
                    role="status"
                  ></b-spinner>
                  <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
              </div>
        </div>
               
            </div>
        </div>

</form>


  

</Layout>
</template>
<style>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


